<template>
    <div class="offcanvas offcanvas-start text-bg-dark" data-bs-scroll="false" data-bs-backdrop="true" tabindex="-1"
        ref="offcanvas">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title">heho</h5>
            <button type="button" class="btn-close text-reset btn-close-white" data-bs-dismiss="offcanvas"
                aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="offcanvas-body bg-body">
            <hr>
            <ul class="nav nav-pills flex-column mb-auto">
                <li class="nav-item">
                    <router-link class="nav-link" to="/">
                        <i class="bi bi-house-heart me-2"></i>
                        Home
                    </router-link>
                </li>
                <li>
                    <router-link class="nav-link" to="/videos">
                        <i class="bi bi-play-btn me-2"></i> Videos
                    </router-link>
                </li>
                <li>
                    <router-link class="nav-link" to="/contact">
                        <i class="bi bi-telephone me-2"></i> Kontakt
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { Offcanvas } from 'bootstrap';

export default {
    name: 'SideNav',
    props: {
        show: Boolean,
    },
    data() {
        return {
            offcanvas: null,
        }
    },
    mounted() {
        this.offcanvas = new Offcanvas(this.$refs['offcanvas']);
    },
    watch: {
        show(val) {
            if (val) {
                this.offcanvas.show();
            } else {
                this.offcanvas.hide();
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.offcanvas {
    height: 100vh;
}
</style>
