import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "@/scss/main.scss";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.scss";

import { Ripple } from 'mdb-ui-kit';

createApp(App).use(router).mount('#app');

Ripple.autoInitial(new Ripple());
