<template>
    <nav class="navbar bg-primary navbar-collapse">
        <div class="container">
            <router-link class="navbar-brand" to="/">
                <img src="./assets/HEHO_Logo_black.png" alt="logo">
            </router-link>
            <a class="nav-link ms-auto mx-2" href="#"><i class="bi-instagram"></i></a>
            <a class="nav-link mx-2" href="#"><i class="bi-tiktok"></i></a>
            <button class="navbar-toggler" type="button" @click="showNav = !showNav">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
    </nav>

    <main>
        <SideNav :show="showNav" @close="showNav = false"></SideNav>

        <div class="mt-5">
            <router-view></router-view>
        </div>

    </main>
</template>

<script>
import SideNav from '@/components/SideNav.vue';

export default {
    name: 'App',
    components: {
        SideNav
    },
    data() {
        return {
            showNav: false,
        };
    },
    // mounted() {
    //     Collapse.getOrCreateInstance(this.$refs.navbar);
    // }
}
</script>

<style lang="scss">
@import "@/scss/vars";

.navbar-brand {
    img {
        height: 32px;
        @include transition(100ms filter);
        filter: drop-shadow(0px 2px 2px adjust-color($light, $alpha: .5));

        &:hover {
            filter: drop-shadow(0px 3px 5px $light);
        }
    }

    // &:hover {
    //   background-color: adjust-color($light, $alpha: 0.5);
    // }

    @include border-radius;
    //background-color: $light;
    //padding-right: 0.25rem !important;
    //padding-left: 0.25rem !important;

}
</style>