<template>
    <div class="container-fluid">

        <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light flex-fill">
            <div class="col-md-5 p-lg-5 mx-auto my-5">
                <h1 class="display-4 fw-normal">Punny headline</h1>
                <p class="lead fw-normal">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
                    eu fugiat nulla pariatur.

                </p>
                <a class="btn btn-outline-secondary" href="#">Coming soon</a>
            </div>
        </div>

        <div class="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3 flex-fill">
            <div class="dark tile t1">
                <div class="content">
                    <h2 class="display-5">Another headline</h2>
                    <p class="lead">And an even wittier subheading.</p>
                    <a class="btn btn-outline-light" href="#">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                        mollit anim id est laborum.
                    </a>

                </div>
            </div>
            <div class="bg-light tile">
                <div class="my-3 p-3">
                    <h2 class="display-5">Another headline</h2>
                    <p class="lead">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex ea commodo consequat.</p>
                </div>
                <div class="bg-dark shadow-sm mx-auto" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;">
                </div>
            </div>
        </div>

        <div class="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3 flex-fill">
            <div class="bg-light tile">
                <div class="my-3 p-3">
                    <h2 class="display-5">Another headline</h2>
                    <p class="lead">And an even wittier subheading.</p>
                </div>
                <div class="bg-dark shadow-sm mx-auto" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;">
                </div>
            </div>
            <div class="text-bg-primary me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden flex-fill">
                <div class="my-3 py-3">
                    <h2 class="display-5">Another headline</h2>
                    <p class="lead">And an even wittier subheading.</p>
                </div>
                <div class="bg-light shadow-sm mx-auto"
                    style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;"></div>
            </div>
        </div>

        <div class="card mb-3">
            <div class="row g-0">
                <div class="col-md-4">
                    <img src="../assets/photos/DSC09593.webp" class="img-fluid rounded-start">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to
                            additional
                            content.
                            This content is a little bit longer.</p>
                        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="row g-0">
                <div class="col-md-4">
                    <img src="../assets/photos/DSC09593.webp" class="img-fluid rounded-start">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to
                            additional
                            content.
                            This content is a little bit longer.</p>
                        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        msg: String
    }
}
</script>

<style scoped lang="scss">
@import "../scss/vars";

.tile {
    // display: flex;
    flex: 1 1 auto;

    text-align: center;

    padding: 1rem;

    @media (min-width: 768px) {
        padding: 3rem;
        margin-right: 1rem;
    }

    background-position: center;
    background-size: cover;
    box-shadow: $box-shadow;

    &.dark {
        background-color: $dark;
        color: $color-contrast-light;

        .content {
            text-shadow: 0px 0px 5px $color-contrast-dark;

            .btn {
                text-shadow: initial;
            }
        }
    }

    &.t1 {
        $center: fade-out($dark, .8);
        $outer: fade-out($dark, .1);
        background: radial-gradient($center, $outer), url("../assets/photos/min/DSC09768.jpg");
        background: radial-gradient($center, $outer),
            image-set(url("../assets/photos/min/DSC09768.avif") type("image/avif"),
                url("../assets/photos/min/DSC09768.webp") type("image/webp"));


        background-position: center;
        background-size: cover;
        backdrop-filter: blur(1px);

        &::after {
            content: "";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            backdrop-filter: blur(3px);
            z-index: -1;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    margin: 1rem 0;
    padding: 1rem 0;

    height: 100%;
    z-index: 5;
}


.card {
    img {
        height: 100%;
        object-fit: cover;
    }
}
</style>
